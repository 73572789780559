@import "../Variables/Variables.scss";


.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    margin: 0;
    border-radius: 16px;
    background: $lightGreen;
    border: solid transparent;
    font-size: 15px;
    font-weight: 700;
    font-family: inherit;
    text-align: center;
    cursor: pointer;
    transition: 0.4s;
    vertical-align: middle;
    white-space: nowrap;
    -webkit-user-select: none;
    user-select: none;
    text-transform: uppercase;
    overflow: visible;
    outline: none;
    border-width: 0 0 4px;
    box-sizing: border-box;
    letter-spacing: .8px;
    line-height: 20px;
}
   
.btn:hover {
    box-shadow: 7px 5px 56px -5px $lightGreen;
}
   
.btn:active {
    transform: scale(0.97);
    box-shadow: 7px 5px 56px -10px $lightGreen;
}


