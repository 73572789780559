@import "../Variables/Variables.scss";

.listed-song {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1px;
  border: solid 2px;
  border-color: $black;
  border-radius: 10px;
  padding: 15px;
  text-decoration: none;
  color: $black;
}

.main-song-title {
  display: flex;
  gap: 10px;
}

.list-part1 {
  padding-left: 5px;
  h4,
  h5 {
    margin: 0px 0px 0px 0px;
  }
}

.list-part2 {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  h5 {
    margin: 0px 0px 0px 0px;
  }
}

@media (max-width: 500px) {
  .list-part2 {
    display: none;
  }
}
