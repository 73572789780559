@import "../Variables/Variables.scss";

.nav {
  display: flex;
  justify-content: space-between;
  background: $black;
  padding: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 8px;
  max-width: 100%;
  .shalom-logo {
    width: 100px;
    height: 100px;
    margin-left: 20px;
  }
}

.nav-sets-list {
  display: flex;
  justify-content: start;

  Button {
    margin-right: 20px;
    margin-left: 10px;
    width: 150px;
  }
}

.nav-search-acc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;

  Button {
    margin-left: 20px;
    margin-right: 20px;
  }
}

h1 {
  color: $white;
}

.title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.btn {
  height: max-content;
  width: max-content;
  padding: 15px;
  padding-bottom: 10px;
  border: none;
  svg {
    transform: scale(1.5);
  }
}
