@import "../Variables/Variables.scss";

body {
  background: $white;
}

header {
  margin: -8px;
  margin-bottom: 8px;
}

main {
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: 100%;
}
