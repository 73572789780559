@import "../Variables/Variables.scss";

.song-title {
  display: flex;
  justify-content: space-around;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
}

.main-info {
  display: flex;
  gap: 5px;
  font-size: x-large;
  align-items: center;
  button {
    border: 2px solid;
    border-color: $black;
    border-radius: 10px;
  }
}

.data-chord {
  color: red;
  font-weight: bold;
}

p span {
  position: relative;
  --chord-offset: 0px;

  &::before {
    content: attr(data-chord);
    position: absolute;
    bottom: 100%;
    left: calc(50% + var(--chord-offset));
    transform: translateX(-50%);
  }
}

pre {
  white-space: break-spaces;
}

.more-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: larger;
  gap: 5px;
}

.sheet-content {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: column;
  font-size: 1.8vw;
  gap: 40px;
  justify-content: start;
  justify-items: start;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  width: max-content;
  height: max-content;
}

/* Responsive for Tablets */
@media (max-width: 700px) {
  .song-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
  }
  .more-info {
    display: flex;
    flex-direction: column;
    font-size: medium;
    align-items: center;
    padding-top: 15px;
  }
  .sheet-content {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
    font-size: 3vw;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 40px;
  }
}

/* Responsive for Smartphones */
@media (max-width: 500px) {
  .song-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
  }
  .more-info {
    display: flex;
    flex-direction: column;
    font-size: medium;
    align-items: center;
    padding-top: 15px;
  }
  .sheet-content {
    display: flex;
    flex-direction: column;
    font-size: 5.5vw;
    padding-right: 15px;
    padding-left: 25px;
  }
}
