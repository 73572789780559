@import "../Variables/Variables.scss";

//  @if [condition] {
//  main {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//  }
// }

.loader {
  width: 350px;
  height: 180px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 30px;
}
.loading {
  width: 100%;
  height: 10px;
  position: relative;
}
.loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 10px;
  background: #002;
  border-radius: 10px;
  z-index: 1;
  animation: loading 0.6s alternate infinite;
}
label {
  color: #002;
  font-size: 18px;
  animation: bit 0.6s alternate infinite;
}

@keyframes bit {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    left: 25%;
  }
  100% {
    left: 50%;
  }
  0% {
    left: 0%;
  }
}
