@import "../Variables/Variables.scss";

.search-box {
  display: flex;
  max-width: 300px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  background: $darkGrey;
  border-radius: 50px;
  position: relative;
}

.search-button {
  color: $white;
  position: absolute;
  right: 8px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(
    --gradient-2,
    linear-gradient(90deg, $lightGreen 0%, $darkGreen 50px)
  );
  border: 0;
  display: inline-block;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
/*button pressing effect*/
.search-button:active {
  color: #fff;
  background-color: #1a1a1a;
  box-shadow: rgba(0, 0, 0, 0.5) 0 10px 20px;
  transform: translateY(-3px);
}

.search-input {
  display: inline;
  border: none;
  background: none;
  outline: none;
  color: $white;
  font-size: 15px;
  padding: 24px 46px 24px 26px;
}

.search-input-2 {
  display: none;
}

/* Responsive for Smartphones */
@media (max-width: 400px) {
  .search-input {
    display: none;
  }
  .search-box {
    @media (hover: hover) and (pointer: course) {
      .search-input-2 {
        display: inline;
        border: none;
        background: none;
        outline: none;
        color: $white;
        font-size: 15px;
        padding: 24px 46px 24px 26px;
      }
    }
  }
}
